import React from 'react';

import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';

import { auth, createUserProfileDocument } from '../../firebase/firebase.utils';

class SignUp extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            displayName: '',
            email: '',
            password: '',
            confirmPassword: '', 
            errors: {}
        }
    }

    handleSubmit = async event => {
        event.preventDefault();

        const { displayName, email, password, confirmPassword } = this.state;

        if(password !== confirmPassword){
            alert('Los passwords no coinciden');
            return;
        }

        try{    

            const { user } = await auth.createUserWithEmailAndPassword(email, password);

            await createUserProfileDocument(user, { displayName })

            this.setState({
                displayName: '',
                email: '',
                password: '',
                confirmPassword: ''
            });

        }catch(error){
            console.error(error)
        }
    }

    handleChange = event => {
        
        const { name, value} = event.target;

        this.setState({ [name]: value});
    }

    render(){

        const { displayName, email, password, confirmPassword, errors } = this.state;
        return(
            <div className="sign-up">
                <h2 className="title">Crear una cuenta</h2>
                <span>Registrate con tu email y una contraseña</span>

                <form onSubmit={this.handleSubmit}>
                    <FormInput
                        type="text"
                        name="displayName"
                        value={displayName}
                        required
                        autoComplete='on'
                        label="Nombre completo"
                        color="White"
                        handleChange={this.handleChange}
                        error={errors["displayName"]}
                    />
                    <FormInput
                        type="email"
                        name="email"
                        value={email}
                        required
                        autoComplete='on'
                        label="Email"
                        color="White"
                        handleChange={this.handleChange}
                    />
                    <FormInput
                        type="password"
                        name="password"
                        value={password}
                        required
                        autoComplete='on'
                        label="Contraseña"
                        color="White"
                        handleChange={this.handleChange}
                    />
                    <FormInput
                        type="password"
                        name="confirmPassword"
                        value={confirmPassword}
                        required
                        autoComplete='on'
                        label="Confirmar contraseña"
                        color="White"
                        handleChange={this.handleChange}
                    />
                    <CustomButton type="submit">Registrarse</CustomButton>
                </form>
            </div>
        )
    }
}
export default SignUp;