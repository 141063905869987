import { createStore, applyMiddleware } from "redux";
// logger is the way we can see in the console all the redux actions 
import logger from "redux-logger";

import rootReducer from "./root-reducer";

const middlewares = [logger];

const store = createStore(rootReducer, applyMiddleware(...middlewares));

export default store;