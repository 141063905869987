import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const config = {
    apiKey: "AIzaSyAHNmMV12hqjrpL510WhreeUw7z4SOnuqA",
    authDomain: "membresias-27f33.firebaseapp.com",
    projectId: "membresias-27f33",
    storageBucket: "membresias-27f33.appspot.com",
    messagingSenderId: "1071911447072",
    appId: "1:1071911447072:web:149087fa28f9d79af22a45"
  };

  export const createUserProfileDocument = async (userAuth, additionalData) => {
    if(!userAuth) return;

    const userRef = firestore.doc(`users/${userAuth.uid}`);

    const snapshop = await userRef.get();

    if(!snapshop.exists){

        const { displayName, email } = userAuth;
        const createdAt = new Date();

        try{
            await userRef.set({
                displayName,
                email,
                createdAt,
                ...additionalData
            })

        }catch(error){
            alert('An error ocurred creating user', error.message);
        }
    }

    return userRef;
  };

  export const checkMembership = async (userAuth) => {
    
    if(!userAuth) return;

    const userRef = firestore.collection('users').doc(`${userAuth.uid}`).collection('membership').doc(`${userAuth.uid}`);

    const snapshop = await userRef.get();
    if(!snapshop.exists){
        return false;
    }else{
        return true;
    }
  };


  export const createUserMembership = async (userAuth, membershipData) => {
    if(!userAuth) return;

    const userRef = firestore.collection('users').doc(`${userAuth.uid}`).collection('membership').doc(`${userAuth.uid}`);

    const snapshop = await userRef.get();

    if(!snapshop.exists){
    
        const { response } = membershipData;

        try{
            await userRef.set({
                response
            })

        }catch(error){
            alert('An error ocurred adding suscription', error.message);
        }
    }else{
        return userRef;
    }

  };



  firebase.initializeApp(config);

  export const auth = firebase.auth();
  export const firestore = firebase.firestore();

  export default firebase;