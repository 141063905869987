import React from "react";

const TerminosCondiciones = () => (
    <div>
        <div className="terminos-condiciones">
            <h2>Términos y Condiciones para Membresías ResMed “Duerme, Sueña y Vive Mejor”</h2>
            <p>Bienvenido a los términos y condiciones ("<strong>Términos</strong>") para membresías ResMed “Duerme, Sueña y Vive Mejor”. Estos Términos se acuerdan entre el suscriptor (El "<strong>Usuario</strong>") y Aerosol Medical Systems SA de CV ("<strong>Aerosol</strong>"), quien es el responsable de prestar el servicio, y regirán los derechos y obligaciones correspondientes. La participación en el Programa de Membresías ResMed está sujeta a estos términos y condiciones mismos que se incorporan a este documento los cuales pueden ser actualizados regularmente y a nuestro aviso de privacidad. El <strong>Usuario</strong> de la membresía ResMed acepta estos términos, condiciones, limitaciones y requerimientos al momento de realizar su suscripción.</p>
            <h3>Funcionamiento de las Membresías ResMed</h3>
            <p>El <strong>Usuario</strong> podrá suscribirse a cualquier modalidad del programa de membresías ResMed en el momento que él lo decida, el cual le otorgará diferentes <strong>Beneficios</strong> de acuerdo con el tipo de membresía que haya adquirido. Dichos <strong>Beneficios</strong> se encuentran claramente especificados en nuestro sitio <a href="https://www.aerosolms.com" target="_blank" rel="noreferrer">www.aerosolms.com</a> así como en el contrato que el <strong>Usuario</strong> tendrá que firmar para concluir el proceso de suscripción a la membresía ResMed.</p>
            <p>Las Membresías solo podrán ser adquiridas directamente en <a href="https://www.aerosolms.com" target="_blank" rel="noreferrer">www.aerosolms.com</a> o en cualquier sucursal de Aerosol.</p>
            <p>En el caso de suscripciones con <strong>Renta de equipo</strong> el <strong>Usuario</strong> firmará un contrato adicional al de la suscripción con el objetivo de establecer los términos y condiciones del programa de renta de equipos por parte de <strong>Aerosol</strong>.</p>
            <h3>Duración y Frecuencia de uso.</h3>
            <p>Al momento de suscribirse el <strong>Usuario</strong> se compromete a pagar una cuota mensual fija por un lapso de <strong>12 meses</strong> forzosos sin opción de cancelación, así mismo, <strong>Aerosol</strong> se compromete a brindar el servicio y/o beneficios en tiempo y forma al <strong>Usuario</strong> mientras la membresía se encuentre vigente.</p>
            <p><strong>Aerosol</strong> renovará cada <strong>12 meses</strong> la membresía del <strong>Usuario</strong>, quien podrá cancelarla después de los primeros <strong>12 meses</strong> de uso o suscripción. Las renovaciones de las suscripciones se generan de forma <strong>automática</strong> hasta el momento de su cancelación.</p>
            <h3>Formas de Pago.</h3>
            <p>El <strong>Usuario</strong> realizará su pago de forma anual o mensual. Los pagos se generan de forma automática por medio de una tarjeta de crédito y/o débito la cual quedará registrada al momento de realizar su suscripción a través de nuestra plataforma de pagos <strong>OpenPay by BBVA Company</strong>. El <strong>Usuario</strong> podrá cambiar o actualizar su método de pago en cualquier momento directamente con el área de atención a clientes de Aerosol.</p>
            <p>En caso de haber alguna situación que imposibilite el cobro mensual de la membresía a la tarjeta registrada durante el proceso de suscripción o el sistema decline el cargo automático, el <strong>Usuario</strong> se obliga a informar de manera inmediata a <strong>Aerosol</strong> para realizar la actualización de los datos bancarios y proceder al cobro de la(s) mensualidades vencidas. </p>
            <h3>Penalizaciones por incumplimiento de pago.</h3>
            <p>Si por algún motivo no se pudiera realizar el cobro mensual de la membresía de forma automática el <strong>Usuario</strong> podrá liquidar su cuota mensual vencida en los próximos <strong>5 días naturales</strong> posteriores a la fecha de corte si ninguna penalización, pasado este tiempo el <strong>Usuario</strong> tendrá una penalización del <strong>5%</strong> sobre el costo mensual de la membresía adquirida por cada periodo vencido. 
            </p>
            <h3>Cancelación de la suscripción.</h3>
            <p>El <strong>Usuario</strong> se compromete a mantener su suscripción por un periodo mínimo de <strong>12 meses</strong>. El <strong>Usuario</strong> podrá cancelar su membresía en cualquier momento teniendo que liquidar las mensualidades pendientes de cobro del plazo forzoso de la suscripción en una sola exhibición con cualquier forma de pago que acepte <strong>Aerosol</strong>.
            </p>
            <p><strong>Aerosol</strong> no podrá reembolsar ninguna cuota mensual derivada de alguna suscripción por ninguna circunstancia y podrá cancelar la suscripción en cualquier momento si existe alguna causa razonable que impida otorgar el servicio de suscripción.
            </p>
            <h3>Limitaciones</h3>
            <p><strong>Aerosol</strong> se reserva el derecho a aceptar o de negar la membresía o suscripción en caso de incumplimiento a los presentes Términos de Servicio, o cualquier otra política o términos de <strong>Aerosol</strong>.
            </p>
            <p><strong>Aerosol</strong> no se hace responsable por el mal uso que se le de a los equipos y/o accesorios otorgados al Usuario. Los gastos adicionales por conceptos de <strong>Reparación, Revisión y Mantenimiento</strong>, que no contemplen la membresía adquirida, serán cubiertos por el <strong>Usuario</strong>.
            </p>
            <p>Cualquier controversia con respecto a las suscripciones del programa de membresías ResMed “Duerme, Sueña y Vive Mejor” será resuelta por el departamento de atención a clientes de <strong>Aerosol</strong> acorde a los lineamientos internos de la empresa y a las leyes vigentes en México.
            </p>
            <h3>Cambios y/o actualizaciones en los Términos y Condiciones.
            </h3>
            <p><strong>Aerosol</strong> se reserva el derecho a modificar estos Términos y Condiciones para su programa de membresías ResMed “Duerme, Sueña y Vive Mejor” en cualquier momento. En caso de realizar cambios sustanciales a los Términos y Condiciones mientras el <strong>Usuario</strong> cuente con una suscripción activa, se notificará dicha situación ya sea mediante <a href="https://www.aerosolms.com" target="_blank" rel="noreferrer">www.aerosolms.com</a>, vía correo electrónico o cualquier otro medio que se encuentre habilitado. Toda actualización a los Términos y Condiciones entrará en vigor al inicio del siguiente Periodo de Cobro de cada Usuario; asimismo, el Usuario reconoce que la continuación en el uso de su suscripción constituye la aceptación expresa de los Términos y Condiciones actualizados. 
            </p>
            <h3>Contacto</h3>
            <p>Para cualquier duda, pregunta o comentario relacionado a los términos y condiciones del programa de membresías ResMed “Duerme, Sueña y Vive Mejor” el <strong>Usuario</strong> podrá contactar a <strong>Aerosol</strong> por cualquiera de estos medios:
            </p>
            <ul>
                <li>Tel: 800 237 6765
                </li>
                <li>E-mail: <a href="mailto:contacto@aerosolms.com">contacto@aerosolms.com</a></li>
                <li>Web: <a href="https://www.aerosolms.com" target="_blank" rel="noreferrer">www.aerosolms.com</a>
                </li>
            </ul>
        </div>
    </div>
)

export default TerminosCondiciones;