import React from 'react';

import SignIn from '../../components/sign-in/sign-in.component';
import SignUp from '../../components/sign-up/sign-up.component';

import imageLogin from '../../assets/imageLogin.jpg';
import caap from '../../assets/cpap.png';
import caap2 from '../../assets/cpap2.png';

const SignInAndSignUp = () => (
    <div className="login">
        <div className="sign-in-and-sign-up">
            <h1>Duerme, sueña, <span>vive mejor.</span></h1>
            <SignIn />
            <SignUp />
            <div className="membersip-products">
                <img alt="capp logo" src={caap} />
                <img alt="capp2 logo" src={caap2} />
            </div>
        </div>
        
        <div className="image-login">
            <img alt="imageLogin" src={imageLogin} />
        </div>
    </div>
    
)

export default SignInAndSignUp;