const HOME_DATA_MASK = [
    {
        id: 1,
        name: "Mascarilla Almohadillas AirFit P10",
        show: true
    },
    {
        id: 2,
        name: "Mascarilla Almohadillas AiFit P30i",
        show: true
    },
    {
        id: 3,
        name: "Mascarilla Nasal Mirage FX",
        show: true
    },
    {
        id: 4,
        name: "Mascarilla Nasal AiFit N20",
        show: true
    },
    {
        id: 5,
        name: "Mascarilla Nasal AiFit N30i",
        show: true
    },
    {
        id: 6,
        name: "Mascarilla Oronasal Quattro Air",
        show: true
    },
    {
        id: 7,
        name: "Mascarilla Oronasal AirFit F20",
        show: true
    },
    {
        id: 8,
        name: "Mascarilla Oronasal AirFit F30",
        show: true
    }
]

export default HOME_DATA_MASK