import React from 'react';
import { connect } from 'react-redux';
import { auth, checkMembership } from '../../firebase/firebase.utils';

import Payment from '../../components/payment/payment.component';
import Header from '../../components/header/header.component';
import MembershipTable from '../../components/membership/membership.component';


import imageMembership from '../../assets/imageForm.jpg';

class HomePage extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            membershipFilled: false
        }
    }
    
    componentDidMount(){

        auth.onAuthStateChanged(async userAuth => {

            if(userAuth){

                checkMembership(userAuth)
                .then(result =>{ this.setState({membershipFilled: result}) })
      
            }
          });
    }

    render(){
        const { membershipFilled } = this.state;
        return(
            <div>
                <Header />
                <div className="payment-membership">
                    <h1>Duerme, sueña, <span>vive mejor.</span></h1>
                    {
                        membershipFilled
                        ? (<MembershipTable />)
                        : (<Payment />)
                    }
                </div>  
                <div className="image-membership">
                    <img alt="imageMembersip" src={imageMembership} />
                </div>
            </div>
        )
    }
   
    
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});

export default connect(mapStateToProps)(HomePage);
