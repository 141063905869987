import React from "react";
import { connect } from "react-redux";
import { auth, createUserMembership } from "../../firebase/firebase.utils";

import FormInput from "../form-input/form-input.component";
import FormSelect from "../form-select/form-select.component";
import CustomButton from "../custom-button/custom-button.component";
import FormRadio from "../form-radio/form-radio.component";
import FormLoading from "../form-loading/form-loading.component";
import FormResponse from "../form-response/form-response.component";
import Formcheckbox from "../form-checkbox/form-checkbox.component";

import OpenPay from "../../assets/openpay_color.png";

import HOME_DATA from "../../pages/home/home.data";
import HOME_DATA_MASK from "../../pages/home/home.mask.data";

class Payment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      privacity: false,
      terms: false,
      token: "",
      membershipSelection: null,
      membershipDescription: "",
      plans: HOME_DATA,
      masks: HOME_DATA_MASK,
      membership: "",
      mask: "",
      membershipName: "",
      holderName: "",
      cardNumber: "",
      cvv2: "",
      expirationMonth: "",
      expirationYear: "",
      phoneNumber: "",
      line1: "",
      line2: "",
      line3: "",
      postalCode: "",
      state: "",
      city: "",
      response: null,
      loading: false,
      errors: {
        membership: "*Debes seleccionar una membresia",
        mask: "*Debes seleccionar un tipo de mascara",
        holderName: "",
        cardNumber: "",
        cvv2: "",
        expirationMonth: null,
        expirationYear: "",
        phoneNumber: "",
        line1: "",
        line2: "",
        line3: "",
        postalCode: "",
        state: "",
        city: "",
        terms: "Debes aceptar términos y condiciones",
        privacity: "Debes aceptar el aviso de privacidad",
      },
    };
  }

  handleChange = (event) => {
    const { name } = event.target;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    let errors = this.state.errors;
    this.setState({ response: "" });

    let index = event.nativeEvent.target.selectedIndex;
    if (index) {
      this.setState({
        membershipName:
          this.state.membershipSelection +
          " " +
          event.nativeEvent.target[index].text,
      });
    }
    console.log(name);
    switch (name) {
      case "terms":
        errors.terms = value ? null : "*Debes aceptar términos y condiciones";
        break;
      case "privacity":
        errors.privacity = value
          ? null
          : "*Debes aceptar el aviso de privacidad";
        break;
      case "membership":
        errors.membership =
          value.length === 0 || value === ""
            ? "*Debes seleccionar una membresia"
            : null;
        break;
      case "mask":
        errors.mask =
          value.length === 0 || value === ""
            ? "*Debes seleccionar un tipo de mascara"
            : null;
        break;
      case "holderName":
        errors.holderName =
          value.length < 5 ? "*Debe contener al menos 5 caracteres." : null;
        break;
      case "cardNumber":
        if (value.length > 16) return;
        /* eslint-disable no-useless-escape */
        let reg = new RegExp(
          "^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35d{3})d{11})$"
        );
        errors.cardNumber = !reg.test(value)
          ? "*Debe contener un número de tarjeta válido."
          : null;
        break;
      case "cvv2":
        if (value.length > 4) return;
        let regCvv = new RegExp("^[0-9]{3,4}$");
        errors.cvv2 = !regCvv.test(value)
          ? "*Debe contener un número válido."
          : null;
        break;
      case "expirationMonth":
        if (value.length > 2) return;
        let regMonth = new RegExp("^0[1-9]|1[0-2]$");
        errors.expirationMonth = !regMonth.test(value)
          ? "*Debe contener un mes válido."
          : null;
        break;
      case "expirationYear":
        if (value.length > 2) return;
        let regYear = new RegExp("^2[1-9]|0[0-9]$");
        errors.expirationYear = !regYear.test(value)
          ? "*Debe ser un año valido"
          : null;

        break;
      case "phoneNumber":
        let regNumber = new RegExp("^[0-9]{10,12}$");
        errors.phoneNumber = !regNumber.test(value)
          ? "Debes poner un número válido"
          : null;
        break;
      case "postalCode":
        let regPostal = new RegExp("^[0-9]{5}$");
        errors.postalCode = !regPostal.test(value)
          ? "Debes poner un código postal válido."
          : null;
        break;
      case "state":
        let regState = new RegExp("^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$");
        errors.state = !regState.test(value)
          ? "Debes poner un estado válido."
          : null;
        break;
      case "city":
        let regCity = new RegExp("^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$");
        errors.city = !regCity.test(value)
          ? "Debes poner una ciudad válida."
          : null;
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
      // if we have an error string set valid to false

      (val) => {
        ((val && val.length > 0) || val === false) && (valid = false);
      }
    );
    return valid;
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    if (!this.validateForm(this.state.errors)) {
      alert(
        "Faltan datos por llenar, por favor revisa de nuevo el formulario."
      );
      return;
    }

    const {
      membershipName,
      membership,
      holderName,
      cardNumber,
      cvv2,
      expirationMonth,
      expirationYear,
      phoneNumber,
      line1,
      line2,
      line3,
      postalCode,
      state,
      city,
      mask,
    } = this.state;

    this.setState({
      membershipSelection: null,
      membership: "",
      membershipName: "",
      holderName: "",
      cardNumber: "",
      cvv2: "",
      expirationMonth: "",
      expirationYear: "",
      phoneNumber: "",
      line1: "",
      line2: "",
      line3: "",
      postalCode: "",
      state: "",
      city: "",
      loading: true,
      response: null,
    });

    const OpenPay = window.OpenPay;

    /** Prod api keys */
    OpenPay.setId('mqke25aqfrf89cf7vwf6');
    OpenPay.setApiKey('pk_c13e56c3de67429cb495c4ba5505d0c9');

    /** Dev api keys */
    //OpenPay.setId("mmg3osgu9zqeabihhs0l");
    //OpenPay.setApiKey("pk_2a47739f91d046dfa93910e50f94748f");
    //OpenPay.setSandboxMode(true);

    const tokenData = {
      card_number: cardNumber,
      holder_name: holderName,
      expiration_year: expirationYear,
      expiration_month: expirationMonth,
      cvv2: cvv2,
      address: {
        city: city,
        postal_code: postalCode,
        line1: line1,
        line2: line2,
        line3: line3,
        state: state,
        country_code: "MX",
      },
    };
    await OpenPay.token.create(
        tokenData,
      (response) => {
        this.setState({ token: response.data.id });
        const email = this.props.currentUser.email;
        if(!membership.length) {
            throw new Error('plan_id must not be empty');
        }

        //fetch(
          //"https://clubdeexpertos.mx/wp-content/themes/clubdeexpertos/testMembresia.php",
          //{
            // URL
            fetch('https://payments.aerosolms.com/sendPayment/payment.php', { // URL
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
              "content-type": "application/json",
            },
            redirect: "follow", // *manual, follow, error
            referrer: "no-referrer", // *client, no-referrer
            body: JSON.stringify({
              token: response.data.id,
              membership,
              membershipSecure:  membership,
              membershipName,
              holderName,
              mask,
              cardNumber,
              email,
              cvv2,
              expirationMonth,
              expirationYear,
              phoneNumber,
              line1,
              line2,
              line3,
              postalCode,
              state,
              city,
            }), // data you send.
          }
        )
          .then((response) => response.json())
          .then((result) => {
            this.setState({ response: result, loading: false });
            if (!result.error) {
              auth.onAuthStateChanged(async (userAuth) => {
                if (userAuth) {
                  createUserMembership(userAuth, this.state);
                }
              });
            }
          });
      },
      () => {
        this.setState({ loading: false });
        alert("An unknow error ocurred, try again.");
      }
    );
  };

  render() {
    const {
      terms,
      privacy,
      membership,
      mask,
      masks,
      holderName,
      cardNumber,
      cvv2,
      expirationMonth,
      expirationYear,
      phoneNumber,
      line1,
      line2,
      line3,
      postalCode,
      state,
      city,
      loading,
      response,
    } = this.state;

    const { plans, membershipSelection } = this.state;

    const { errors } = this.state;

    return (
      <div className="payment">
        <form onSubmit={this.handleSubmit} id="payment-form">
          <div className="membership-selection">
            <h2>Selecciona una membresía</h2>
            <FormRadio
              type="radio"
              name="membershipSelection"
              value="Classic"
              onChange={this.handleChange}
              children="Clásica"
            />
            <FormRadio
              type="radio"
              name="membershipSelection"
              value="Premium"
              onChange={this.handleChange}
              children="Premium"
            />
          </div>

          {membershipSelection ? (
            <FormSelect
              options={plans}
              type={membershipSelection}
              name="membership"
              value={membership}
              onChange={this.handleChange}
              error={errors.membership}
            />
          ) : null}

          {membershipSelection ? (
            <FormSelect
              options={masks}
              type={membershipSelection}
              name="mask"
              mascarilla="true"
              value={mask}
              onChange={this.handleChange}
              error={errors.mask}
            />
          ) : null}
          {plans
            .filter((item, idx) => item.id_plan === membership)
            .map((item) => (
              <p className="membership-name" key={item.id}>
                {item.description}
              </p>
            ))}

          <FormInput
            type="text"
            name="holderName"
            value={holderName}
            label="Nombre del tarjeta habiente"
            required
            autoComplete="off"
            col2
            onChange={this.handleChange}
            error={errors.holderName}
          />
          <FormInput
            type="number"
            name="cardNumber"
            value={cardNumber}
            label="Número de tarjeta"
            required
            autoComplete="off"
            col2
            onChange={this.handleChange}
            error={errors.cardNumber}
          />

          <FormInput
            type="number"
            name="expirationMonth"
            value={expirationMonth}
            label="Mes (MM)"
            required
            autoComplete="off"
            col2
            onChange={this.handleChange}
            error={errors.expirationMonth}
          />
          <FormInput
            type="number"
            name="expirationYear"
            value={expirationYear}
            label="Año (AA)"
            required
            autoComplete="off"
            col2
            onChange={this.handleChange}
            error={errors.expirationYear}
          />
          <FormInput
            type="password"
            name="cvv2"
            value={cvv2}
            label="CVV"
            required
            autoComplete="off"
            col2
            onChange={this.handleChange}
            error={errors.cvv2}
          />
          <FormInput
            type="number"
            name="phoneNumber"
            value={phoneNumber}
            label="Número de teléfono"
            required
            autoComplete="off"
            col2
            onChange={this.handleChange}
            error={errors.phoneNumber}
          />
          <div className="direccion">
            <h3>Dirección</h3>
            <FormInput
              type="text"
              name="line1"
              value={line1}
              label="Calle y número exterior"
              required
              autoComplete="off"
              col2
              onChange={this.handleChange}
            />
            <FormInput
              type="text"
              name="line2"
              value={line2}
              label="Colonia o Asentamiento"
              required
              autoComplete="off"
              col2
              onChange={this.handleChange}
            />
            <FormInput
              type="text"
              name="line3"
              value={line3}
              label="Delegación o Municipio"
              required
              autoComplete="off"
              col2
              onChange={this.handleChange}
            />
            <FormInput
              type="number"
              name="postalCode"
              value={postalCode}
              label="Código Postal"
              required
              autoComplete="off"
              col2
              onChange={this.handleChange}
              error={errors.postalCode}
            />
            <FormInput
              type="text"
              name="state"
              value={state}
              label="Estado"
              required
              autoComplete="off"
              col2
              onChange={this.handleChange}
              error={errors.state}
            />
            <FormInput
              type="text"
              name="city"
              value={city}
              label="Ciudad"
              required
              autoComplete="off"
              col2
              onChange={this.handleChange}
              error={errors.city}
            />
          </div>
          <div className="form-checkbox">
            <Formcheckbox
              type="checkbox"
              name="terms"
              onChange={this.handleChange}
              checked={terms}
              error={errors.terms}
              required
            >
              Acepto{" "}
              <a href="/terminos-condiciones" target="_blank">
                términos y condiciones
              </a>
            </Formcheckbox>
            <Formcheckbox
              type="checkbox"
              name="privacity"
              onChange={this.handleChange}
              checked={privacy}
              error={errors.privacity}
              required
            >
              <a href="/aviso-privacidad" target="_blank">
                Aviso de privacidad
              </a>
            </Formcheckbox>
          </div>

          {loading ? null : (
            <div className="group-buttons">
              <CustomButton type="submit">Pagar</CustomButton>
            </div>
          )}
          {loading ? <FormLoading /> : null}
          {response ? (
            <FormResponse
              response={response}
              membership={membershipSelection}
            />
          ) : null}
          <div className="powered-by">
            <p>
              <img src={OpenPay} alt="openPay Logo" />
            </p>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(Payment);
