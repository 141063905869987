import React from 'react';

const FormInput = ({ handleChange, label, error, col2, color,  ...otherProps}) => (
    <div className={`${col2 ? 'col-2' : ''} group`}>
        <input 
            className="form-input" 
            onChange={handleChange} 
            { ...otherProps }
        />
        {
            label ? (<label 
                style={{
                    color: `${color === 'White' ? '#fff' : '#525251'}`
                }}
                className={`${
                otherProps.value.length ? 'shrink' : ''
            } form-input-label `}>{label}</label>) : null
        }
        {error 
            ? (<span className='form-error'>{error}</span>)
            : null
        }

    </div>
    
)

export default FormInput;