import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'; 
import { auth } from '../../firebase/firebase.utils';

import CustomButton from '../custom-button/custom-button.component';

const Header = ({ currentUser }) => (
    <div>
        <header>
        {
            currentUser
            ? (
                <div className="loggin-info">
                    <p>¡Hola! {currentUser.displayName} </p>
                    <CustomButton onClick={() => auth.signOut()} >Cerrar sesión</CustomButton>
                </div>  
            ) : (
                <div className="loggin-info">
                    <Link to="/sign-in">
                        <CustomButton >Cerrar sesión</CustomButton>
                    </Link>
                    
                </div>  
            )
        }
            
        </header>
    </div>
);


// mapStateToProps is a standard of codebases, need to be a object
const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});

export default connect(mapStateToProps)(Header);