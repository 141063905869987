import React from 'react';

import { auth } from '../../firebase/firebase.utils';

import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';

class SignIn extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            email: '',
            password: '',
            error: ''
        }
    }

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({email: '', password: '', error: ''});

        const { email, password } = this.state;

        try{

            await auth.signInWithEmailAndPassword(email, password);
            this.setState({email: '', password: ''});
            
        }catch(error){
            this.setState({error: error.message})
        }

    }

    handleChange = event => {
        const { value, name } = event.target

        this.setState({ [name]: value });
    }

    render(){
        return(
            <div className="sign-in">
                <h2>Ya tengo una cuenta</h2>
                <span>Inicia sesión con tu email y contraseña</span>

                <form onSubmit={this.handleSubmit}>
                    <FormInput
                        type="email" 
                        name="email" 
                        value={this.state.email} 
                        required
                        label="Email" 
                        autoComplete="on"
                        color="White"
                        handleChange={this.handleChange}
                    />
                    <FormInput
                        type="password" 
                        name="password" 
                        value={this.state.password} 
                        required
                        label="Password" 
                        autoComplete="off"
                        color="White"
                        handleChange={this.handleChange}
                    />
                    <CustomButton>Iniciar sesión</CustomButton>
                </form>{
                    this.state.error
                    && (<p className="error-login">{this.state.error}</p>)
                }
                
            </div>
        )
    }
}

export default SignIn;