import React from 'react';

const FormRadio = ({ handleChange, children, ...otherProps }) => (
    <div className="radio">
        <label
            style={{
                color: `${children === 'Clásica' ? '#0dd79d' : '#594aff'}`
            }}
        >
            <input 
                onChange={handleChange}
                { ...otherProps }
            />
            { children }
        </label>
    </div>
)

export default FormRadio;