import React from 'react';

class FormSelect extends React.Component {
    
    render(){

        const { handleChange, options, type, error, mascarilla, ...otherProps } = this.props;

        return(
            <div className="group-select">
                <select 
                className="form-select" 
                onChange={handleChange} 
                defaultChecked=""
                style={{
                    backgroundColor: `${type === 'Classic' ? '#0dd79d' : '#594aff'}`
                }}
                {...otherProps}
                >
                {
                    mascarilla ? (
                        <option default value="">Tipo de mascarilla</option>  
                        ) : (
                        <option default value="">Membresía {type}</option>
                        )
                }
                {
                    mascarilla ? (
                        options
                        .filter((item, idx) => item.show)
                        .map((item) => (
                            
                            <option 
                                key={item.id} 
                                value={item.name}>
                                {item.name}
                            </option>
                           
                        ))
                    ): (
                        options
                        .filter((item, idx) => item.membership === type && item.show)
                        .map((item) => (
                            
                            <option 
                                key={item.id} 
                                value={item.id_plan}>
                                {item.title} - ${item.price} Mensual
                            </option>
                           
                        ))
                    )
                }

            </select>

            {error 
                ? (<span className='form-error'>{error}</span>)
                : null
            }
        </div>
        )
    }
}


export default FormSelect;