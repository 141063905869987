import React from 'react';

import CustomButton from '../custom-button/custom-button.component';

import imageCard from '../../assets/card.png'
import imageCard1 from '../../assets/card1.png'

const FormResponse = ({response, membership}) => {
    const { name, card } = response
    return(
        (response.error) 
        ? (
            <div className="response-error">
                {response.error}
            </div>
        )
        : (
            <div className="response-success">
                <div className="success-message">
                    <div className="success-header">
                        <h2><strong>Duerme</strong>, sueña, <span className="green">vive mejor</span></h2>
                        <p className="success-title">¡Transacción <strong>exitosa!</strong></p>
                        <span className="detail-title">Los detalles de tu compra se han enviado al correo que nos proporcionaste.</span>
                    </div>
                    
                    <div className="success-body">
                        <div className="success-body__image">
                        {
                            membership === 'Clásica' ? <img className="success-image" src={imageCard1} alt="Membership Card"/> : <img className="success-image" src={imageCard} alt="Membership Card"/>
                        }   
                        </div>
                        <div className="success-body__description">
                            <h3>Membresía <b>{membership}</b></h3>
                            <p>Comprado por:</p>
                            <span className="success-name">{name}</span>

                            <div className="card-number">
                                <p><strong>Número de tarjeta:</strong></p>
                                <span className="green">{
                                    card
                                    .split('')
                                    .map((item, idx, arr) => (idx < arr.length - 3) ? 'X' : item)}</span>
                            </div>
                        </div>
                        
                    </div>
                    <div className="success-footer">
                        <span>En un plazo <strong>no mayor a 24 horas</strong>, uno de nuestros ejecutivos se pondrá en contacto contigo para <strong>gestionar el envío</strong> a la dirección guardada en el registro o a la que nos indiques.</span>                        
                        <CustomButton type="button" onClick={() => {window.location.href="/"}}>Continuar</CustomButton>
                    </div>

                </div> 
               
            </div>
        )
    )
        
}

export default FormResponse;