import React from 'react';

const Formcheckbox = ({ handleChange, error, children, ...otherProps }) => (
    <div className="checkbox">
        <label>
            { children }
            <input 
                onChange={handleChange}
                { ...otherProps }
            />
            <span className="checkmark"></span>
        </label>
        {error 
            ? (<span className='form-error'>{error}</span>)
            : null
        }
    </div>
);

export default Formcheckbox;