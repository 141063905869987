import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { auth, createUserProfileDocument } from "./firebase/firebase.utils";
import { setCurrentUser } from "./redux/user/user.actions";

import SignInAndSignUp from "./pages/sign-in-and-sign-up/sign-in-and-sign-up.component";
import HomePage from "./pages/home/home.component";
import TerminosCondiciones from "./pages/terminos-condiciones/terminos-condiciones.component";
import AvisoPrivacidad from "./pages/aviso-privacidad/aviso-privacidad.component";

import "./App.scss";
import FormLoading from "./components/form-loading/form-loading.component";

const App = ({ currentUser, setCurrentUser }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const userRef = createUserProfileDocument(userAuth);
        (await userRef).onSnapshot((snapShot) => {
          setCurrentUser({
            id: snapShot.id,
            ...snapShot.data(),
          });
          setLoading(false);
        });
      } else {
        setCurrentUser(userAuth);
        setLoading(false);
      }

      return () => {
        unsubscribeFromAuth();
      };
    });
  }, [setCurrentUser]);

  if (loading) {
    return <FormLoading />;
  }

  return (
    <div>
      <Routes>
        <Route
          exact
          path="/"
          element={currentUser ? <HomePage /> : <Navigate to="/sign-in" />}
        />
        <Route
          exact
          path="/sign-in"
          element={currentUser ? <Navigate to="/" /> : <SignInAndSignUp />}
        />
        <Route
          exact
          path="/terminos-condiciones"
          element={<TerminosCondiciones />}
        />
        <Route
          exact
          path="/aviso-privacidad"
          element={<AvisoPrivacidad />}
        />
      </Routes>
    </div>
  );
};

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
