const HOME_DATA = [
    {
      id: 1,
      title: 'Accesorios para CPAP',
      price: '680.00',
      id_plan: 'pmo2hfarcwg1czibjnhx',
      membership: 'Classic',
      show: true,
      description: "Membresía ideal para pacientes que ya cuenten con su equipo AirSense CPAP, Elite o Auto. Incluye: 1 Mascarilla Nasal u Oronasal, 2 Filtros, 1 Depósito de agua para humidificador, 1 Manguera o tubo SlimLine, 1 Mantenimiento preventivo y Beneficios exclusivos. Suscripción a 12 meses."
    },
    {
      id: 2,
      title: 'Accesorios para VPAP',
      price: '740.00',
      id_plan: 'pvnzhfbswzutgcu2ipn8',
      membership: 'Classic',
      show: true,
      description: "Membresía ideal para pacientes que ya cuenten con su equipo AirCurve S, VAuto, ST o ST-A. Incluye: 1 Mascarilla Nasal u Oronasal, 2 Filtros, 1 Depósito de agua para humidificador, 1 Manguera o tubo SlimLine, 1 Mantenimiento preventivo y Beneficios exclusivos. Suscripción a 12 meses."
    },
    {
      id: 3,
      title: 'CPAP Auto con Accesorios',
      price: '2,100.00',
      id_plan: 'pgxvgm40ytbclercc5rk',
      membership: 'Classic',
      show: true,
      description: "Membresía ideal para pacientes que quieran adquirir un equipo AirSense Autoset o CPAP Auto. Incluye: 1 CPAP AirSense Autoset, 1 Mascarilla Nasal u Oronasal, 2 Filtros, 1 Depósito de agua para humidificador, 1 Manguera o tubo SlimLine, 1 Mantenimiento preventivo y Beneficios exclusivos. Suscripción a 12 meses."
    },
    {
        id: 4,
        title: 'VPAP Auto con Accesorios',
        price: '3,700.00',
        id_plan: 'ptkkpien7lfh7bnr6j89',
        membership: 'Classic',
        show: true,
        description: "Membresía ideal para pacientes que quieran adquirir un equipo AirCurve VAuto o VPAP Auto. Incluye: 1 VPAP AirCurve VAuto, 1 Mascarilla Nasal u Oronasal, 2 Filtros, 1 Depósito de agua para humidificador, 1 Manguera o tubo SlimLine, 1 Mantenimiento preventivo y Beneficios exclusivos. Suscripción a 12 meses."
    },
      {
        id: 5,
        title: 'CPAP Auto en Renta con Accesorios',
        price: '2,520.00',
        id_plan: 'p2otlr5aqhpzi9ay3kgt',
        membership: 'Classic',
        show: true,
        description: "Membresía ideal para pacientes que quieran rentar un equipo AirSense Autoset o CPAP Auto. Incluye: 1 CPAP AirSense Autoset en renta, 1 Mascarilla Nasal u Oronasal, 2 Filtros, 1 Depósito de agua para humidificador, 1 Manguera o tubo SlimLine, 1 Mantenimiento preventivo y Beneficios exclusivos. Suscripción a 12 meses."
      },
      {
        id: 6,
        title: 'VPAP Auto en Renta con Accesorios',
        price: '4,430.00',
        id_plan: 'pn2ovxjso71y5nqg0qoq',
        membership: 'Classic',
        show: true,
        description: "Membresía ideal para pacientes que quieran rentar un equipo AirCurve VAuto o VPAP Auto. Incluye: 1 VPAP AirCurve VAuto en renta, 1 Mascarilla Nasal u Oronasal, 2 Filtros, 1 Depósito de agua para humidificador, 1 Manguera o tubo SlimLine, 1 Mantenimiento preventivo y Beneficios exclusivos. Suscripción a 12 meses."
      },
      {
        id: 7,
        title: 'Accesorios para CPAP',
        price: '1,190.00',
        id_plan: 'puncpdgcuklvwrz6z3pa',
        membership: 'Premium',
        show: true,
        description: "Membresía ideal para pacientes que ya cuenten con su equipo AirSense CPAP, Elite o Auto. Incluye: 2 Mascarillas Nasales u Oronasales, 1 Almohada Contour, 2 Filtros, 1 Depósito de agua para humidificador, 1 Manguera o tubo ClimateLine Air, 1 Mantenimiento preventivo y Beneficios exclusivos. Suscripción a 12 meses."
      },
      {
        id: 8,
        title: 'Accesorios para VPAP',
        price: '1,260.00',
        id_plan: 'pnqtsnviou5h4rs17cox',
        membership: 'Premium',
        show: true,
        description: "Membresía ideal para pacientes que ya cuenten con su equipo AirCurve S, VAuto, ST o ST-A. Incluye: 2 Mascarillas Nasales u Oronasales, 1 Almohada Contour, 2 Filtros, 1 Depósito de agua para humidificador, 1 Manguera o tubo ClimateLine Air, 1 Mantenimiento preventivo y Beneficios exclusivos. Suscripción a 12 meses."
      },
      {
        id: 9,
        title: 'CPAP Auto con Accesorios',
        price: '2,700.00',
        id_plan: 'pkqmxo4is0usd8dpolzz',
        membership: 'Premium',
        show: true,
        description: "Membresía ideal para pacientes que quieran adquirir un equipo AirSense Autoset o CPAP Auto. Incluye: 1 CPAP AirSense Autoset, 1 Almohada Contour, 2 Mascarillas Nasales u Oronasales, 2 Filtros, 1 Depósito de agua para humidificador, 1 Manguera o tubo ClimateLine Air, 1 Mantenimiento preventivo y Beneficios exclusivos. Suscripción a 12 meses."
      },
      
      {
          id: 10,
          title: 'VPAP Auto con Accesorios',
          price: '4,200.00',
          id_plan: 'pdgmtleseqjykxfltkdm',
          membership: 'Premium',
          show: true,
          description: "Membresía ideal para pacientes que quieran adquirir un equipo AirCurve VAuto o VPAP Auto. Incluye: 1 VPAP AirCurve VAuto, 1 Almohada Contour, 2 Mascarillas Nasales u Oronasales, 2 Filtros, 1 Depósito de agua para humidificador, 1 Manguera o tubo ClimateLine Air, 1 Mantenimiento preventivo y Beneficios exclusivos. Suscripción a 12 meses."
      },
      
        {
          id: 11,
          title: 'CPAP Auto en Renta con Accesorios',
          price: '3,150.00',
          id_plan: 'peddbqqrvzhl1jzy1ivb',
          membership: 'Premium',
          show: true,
          description: "Membresía ideal para pacientes que quieran rentar un equipo AirSense Autoset o CPAP Auto. Incluye: 1 CPAP AirSense Autoset en renta, 2 Mascarillas Nasales u Oronasales, 1 Almohada Contour, 2 Filtros, 1 Depósito de agua para humidificador1 Manguera o tubo ClimateLine Air, 1 Mantenimiento preventivo y Beneficios exclusivos. Suscripción a 12 meses."
        },
        {
          id: 12,
          title: 'VPAP Auto en Renta con Accesorios',
          price: '4,960.00',
          id_plan: 'pdsdl1do75bcxhyjrnme',
          membership: 'Premium',
          show: true,
          description: "Membresía ideal para pacientes que quieran rentar un equipo AirCurve VAuto o VPAP Auto. Incluye: 1 VPAP AirCurve VAuto en renta, 2 Mascarillas Nasales u Oronasales, 1 Almohada Contour, 2 Filtros, 1 Depósito de agua para humidificador1 Manguera o tubo ClimateLine Air, 1 Mantenimiento preventivo y Beneficios exclusivos. Suscripción a 12 meses."
        }
  ]

  export default HOME_DATA;