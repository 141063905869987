import React from "react";
import { connect } from "react-redux";
import { auth, createUserMembership } from '../../firebase/firebase.utils';

class MembershipTable extends React.Component{
    

    constructor(){
        super();

        this.state = {
            membershipDat: {}
        }
    }
    componentDidMount(){

        auth.onAuthStateChanged(async userAuth => {

            if(userAuth){

                    const userRef = createUserMembership(userAuth, this.state);
                    (await userRef).onSnapshot(snapShot => {
                        this.setState({ membershipDat: snapShot.data()});
                    });
      
            }
          });
    }

    render(){
        const { membershipDat } = this.state;
        return(
            <div className="successMembership">
          
                <h2>La cuenta de correo: <strong>{this.props.currentUser.email}</strong> ya tiene una suscripción habilitada:</h2>
                <p>Detalles de la suscripción:</p>
                {

                    Object.keys(membershipDat).map((val, idx, array) => {
                        return (
                            <ul key={idx}>
                                <li><strong>Suscripción:</strong> {membershipDat[val].membership}</li>
                                <li><strong>Nombre:</strong> {membershipDat[val].name}</li>
                                <li><strong>Teléfono:</strong> {membershipDat[val].phone_number}</li>
                                <li><strong>Fecha de creación:</strong> {membershipDat[val].creation_date}</li>
                                <li><strong>Número de la tarjeta:</strong> {membershipDat[val].card}</li>
                                <li><strong>Dirección de envío:</strong> {membershipDat[val].address}</li>  
                            </ul>
                        )
                    })
         
                }
                <p>Si tiene dudas sobre su suscripción no dude en contactarnos: <a href="tel:8002376765">800 237 6765</a></p>
            </div>
            
        )
    }
    
};

const mapStateToProps = ({ user }) => ({
    currentUser: user.currentUser
  })
  
export default connect(mapStateToProps)(MembershipTable);