import { userActionTypes } from "./user.types";

// Initial state of out user state
const INITIAL_STATE = {
    currentUser: null
}

// All actions that can realize in our state
const userReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        // List of all actions
        case userActionTypes.SET_CURRENT_USER:
            // Always return and object because React don't re render if it's the same object
            return {
                ...state,
                currentUser: action.payload
            }

        // If nothing fires an action return the actual state
        default:
            return state;
    }
}

export default userReducer;