import React from 'react';

const FormLoading = () => (
    <div className="loading">
        <div className="loading-center">
            <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <span>Espere...</span>
        </div>
        
    </div>
)

export default FormLoading;